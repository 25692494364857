import React from "react"
import styles from "./hero-feature-page.module.scss"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Icon from '../../../../../images/icons/white-under-arrow-icon.svg'
import Form from '../../../../../components/forms/hero-form'
import { openOverlay, openSuccessOverlay } from "../../../../../utils/overlay"

const Index = ({ title, subtitle, list, idUnderBlock, description,  offsetHeader }) => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.description}>{description}</p>
          <p className={styles.price}>от 802 руб./мес.</p>
          <ul className={styles.list}>
            {list.map((el, index) =>
              <li key={index} dangerouslySetInnerHTML={{ __html: el }}></li>
            )}
          </ul>
          <button className={styles.button}
            onClick={() => openOverlay("hero")}>
            Бесплатный тест CRM 10 дней
          </button>
        </div>
        <Form
          className={styles.heroForm}
          title="Бесплатный тест CRM <br /> 10 дней"
          description="Заполните эту форму и получите письмо с доступом в систему на свой email"
          btnTitle="Зарегистрироваться"
          onSuccess={()=> {openSuccessOverlay()}}
        />
      </div>
    </div>
    <AnchorLink className={styles.anchor} offset={() => offsetHeader} href={idUnderBlock}><img src={Icon} alt="" />Прокрутите ниже</AnchorLink>
  </div>
)

export default Index
