import React from "react"
import styles from "./info.module.scss"

const MainInfo = ({ title, subtitle, ratingNumber, ratingText }) => (
    <div className={styles.info}>
        <div className={styles.head}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.subtitle}>{subtitle}</p>
        </div>
        <div className={styles.blocks}>
            {ratingNumber
                ? <div className={styles.left}>
                    <div className={styles.number}>
                        {ratingNumber}
                    </div>
                    <div className={styles.text}>
                        {ratingText}
                    </div>
                </div>
                : <div className={styles.left}>
                    <div className={styles.number}>
                        №1
                    </div>
                    <div className={styles.text}>
                        в рейтинге CRM для детских центров и франшиз*
                    </div>
                </div>
            }
            <div className={styles.right}>
                <div className={styles.wrap}>
                    <div className={styles.title}>
                        <svg width="73" height="36" viewBox="0 0 73 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M42.1448 30.465L42.4042 30.7128C44.9074 33.2303 48.0972 34.9452 51.5702 35.6404C55.0431 36.3356 58.6432 35.9799 61.915 34.6184C65.1868 33.2568 67.9834 30.9506 69.951 27.9913C71.9186 25.032 72.9688 21.5527 72.9688 17.9935C72.9688 14.4342 71.9186 10.9549 69.951 7.99566C67.9834 5.03641 65.1868 2.73015 61.915 1.3686C58.6432 0.00704629 55.0431 -0.348624 51.5702 0.346577C48.0972 1.04178 44.9074 2.75662 42.4042 5.27418L42.3264 5.19595C41.4962 6.18689 39.2779 8.41652 33.3754 14.3231L24.2947 23.4502C23.2239 24.5249 21.8601 25.2565 20.3757 25.5524C18.8913 25.8483 17.353 25.6953 15.9551 25.1127C14.5572 24.5301 13.3626 23.5441 12.5221 22.2793C11.6815 21.0144 11.2329 19.5275 11.2329 18.0065C11.2329 16.4855 11.6815 14.9986 12.5221 13.7338C13.3626 12.4689 14.5572 11.4829 15.9551 10.9003C17.353 10.3177 18.8913 10.1647 20.3757 10.4606C21.8601 10.7566 23.2239 11.4881 24.2947 12.5628L24.5412 12.8236L31.7798 5.53496L31.5333 5.28722C29.0301 2.76965 25.8403 1.05482 22.3674 0.359618C18.8944 -0.335582 15.2944 0.0200828 12.0226 1.38163C8.75075 2.74318 5.95415 5.04945 3.98656 8.00871C2.01897 10.968 0.96875 14.4473 0.96875 18.0065C0.96875 21.5658 2.01897 25.0451 3.98656 28.0043C5.95415 30.9636 8.75075 33.2699 12.0226 34.6314C15.2944 35.993 18.8944 36.3486 22.3674 35.6534C25.8403 34.9582 29.0301 33.2434 31.5333 30.7258L49.6299 12.5368C50.3403 11.8219 51.1838 11.2547 52.1123 10.8674C53.0407 10.4802 54.036 10.2806 55.0413 10.28C56.0465 10.2794 57.042 10.4778 57.971 10.8639C58.8999 11.25 59.7441 11.8162 60.4554 12.5302C61.1666 13.2443 61.731 14.0921 62.1162 15.0253C62.5015 15.9586 62.7001 16.9589 62.7007 17.9693C62.7013 18.9797 62.5038 19.9803 62.1197 20.914C61.7356 21.8477 61.1722 22.6962 60.4618 23.4111C59.025 24.8491 57.08 25.6564 55.0524 25.6564C53.0247 25.6564 51.0797 24.8491 49.6428 23.4111L49.3964 23.1503L42.1448 30.465Z" fill="#03C4A4" />
                        </svg>
                    </div>
                    <div className={styles.text}>бесконечное количество пользователей</div>
                </div>
                <div className={styles.wrap}>
                    <div className={styles.title}><span>15&nbsp;</span>мин.</div>
                    <div className={styles.text}>среднее время ответа техподдержки</div>
                </div>
                <div className={styles.wrap}>
                    <div className={styles.title}><span>10&nbsp;</span>дней</div>
                    <div className={styles.text}>бесплатного доступа <br />к системе</div>
                </div>
                <div className={styles.wrap}>
                    <div className={styles.title}>в<span>&nbsp;3&nbsp;</span>раза</div>
                    <div className={styles.text}>меньше времени на управление бизнесом**</div>
                </div>
            </div>
        </div>
        <div className={styles.prompt}>* По данным сервиса CRMindex в рейтинге лучших «CRM для детских центров», «CRM для франшиз».</div>
        <div className={styles.prompt}>** По данным отзыва о Параплан CRM детского клуба «Волшебники».</div>
    </div >
)

export default MainInfo