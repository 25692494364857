import React from "react"
import styles from "./cards.module.scss"

const Cards = () => (
    <div className={styles.cards}>
        <div className="container">
            <h2 className={`${styles.title} ${styles.center}`}>Используйте технологии, которые приносят результат</h2>
            <div className={styles.wrap}>
                <div className={`${styles.card} ${styles.purple}`}>
                    <h3 className={styles.title}>Легкость</h3>
                    <div className={styles.text}>
                        Параплан одновременно простой
                        в освоении и функциональный
                        в работе. Более 80% клиентов готовы рекомендовать Параплан коллегам.
                        Внедрение CRM в бизнес-процессы центра или салона не поставит вас в тупик.
                    </div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.users}`}>Бесплатный перенос базы клиентов или лидов</div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.monitor}`}>Минималистичный современный интерфейс</div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.thumb}`}>Для любого пользовательского опыта</div>
                </div>
                <div className={`${styles.card} ${styles.blue}`}>
                    <h3 className={styles.title}>Автоматизация</h3>
                    <div className={styles.text}>
                        CRM-система объединяет множество интеграций: мессенджеры, <br /> онлайн-касса, сервисы для SMS и Email рассылок,
                        формы сбора заявок и <br /> онлайн-записи клиентов для сайта и социальных сетей, IP-телефония, Интернет-эквайринг.
                    </div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.grid}`}>Объединение разных приложений в одной системе</div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.settings}`}>Автоматические действия вместо ручного труда </div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.banknote}`}>Экономия ваших ресурсов на разработку</div>
                </div>
                <div className={`${styles.card} ${styles.green}`}>
                    <h3 className={styles.title}>Надежность</h3>
                    <div className={styles.text}>
                        Параплан CRM - продукт российской IT-компании Haulmont. Система зарегистрирована в Реестре отечественного ПО.
                        Все данные клиентов под защитой от удаления и копируются в резервное хранилище. Обслуживание серверов ведется 24/7.
                    </div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.book}`}>Полная история работы с <br /> клиентом и лидом</div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.user}`}>Отдельный доступ у каждого пользователя</div>
                    <div className={`${styles.advantages} ${styles.icon} ${styles.tool}`}>Гибкая настройка ролей сотрудников</div>
                </div>
            </div>
        </div>
    </div>
)

export default Cards